
@import url('https://use.typekit.net/tmt5fhq.css');


body, h1, h2, h3, h4, h5, h6, p { 
    font-family: "pf-grand-gothik-variable", Arial, Helvetica, sans-serif;
    font-display: swap; /* Ensures text is visible until the font is fully loaded */
}

$heading-font: "pf-grand-gothik-variable", Arial, Helvetica, sans-serif;
$font: "pf-grand-gothik-variable", Arial, Helvetica, sans-serif;

/* breakpoints */
$tablet-breakpoint: 768px;
$desktop-breakpoint: 1280px;
$mobile-breakpoint: 320px;

/* colors */
$black: #1F2022;
$softblack: #353639;
$brand1: #F3EFEB;
$brand2: #FFFFAD;
$brand3: #D5ADFF;
$brand4: #FFFFD6;
$white: #fcfcfc;

$mobile-padding: 1rem;
$tablet-padding: 1rem;
$desktop-padding: 1rem;
