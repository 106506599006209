@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.case-study {

 background-color: $brand4;
  
  &__content {
    display: flex;
    flex-direction: column;
    padding: 3rem 1rem 0 1rem;

    @include tablet {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start; /* Align content to the top initially */
  }
  }
  
  &__text {
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-top: 2rem;

  h2 {
    padding-bottom: 1rem;
  }

    @include tablet {
    flex: 1; /* Half of the width */
    padding: 4rem 0 4rem 4rem; /* Adjust spacing between text and image */
    }

    h6 {
        letter-spacing: 0.25rem;
    }
  }

  &__image {
   
    align-self: flex-end;
    text-align: center;

    @include tablet {
    flex: 1; /* Half of the width */
    max-width: 100%;
    
    }

    img {
      width: 75%;
      height: auto;
      object-fit: cover;
      align-self: flex-end;

      // Initial state for animation
      opacity: 0; // Ensure the image is initially invisible
      animation: none; // Prevent animation from triggering immediately

      @include tablet {
        width: 90%;
      }
    }
  }

  
}
