@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;


.portfolio-page {

  background-color: $white;
  
    @include tablet {
        // Additional tablet-specific styles
      }
    
      @include desktop {
        // Additional desktop-specific styles
      }
    
      h1 {
        padding: 3rem 0 3rem 0;
      }
      

&__portfolio {

padding: 1rem 1rem 8rem 1rem;
}


}