@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.contact-me-button {
  display: block;
  border: none;
  border-radius: 2px;
  padding: 10px 16px;
  font-family: $font;
  font-size: 16px;
  color: $black;
  background-color: $brand3;
  border: 1px solid $black;
  letter-spacing: 0.0125rem;
  text-decoration: none;
  text-align: center;
  transition: transform 0.3s ease;

  &:hover {
    background-color: $brand2;
    letter-spacing: 0.0125rem;
    transform: scale(1.1);

  @include tablet {
    font-size: 14px;
  }
}
}