@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.email-me {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: $font;
  font-size: 16px;
  padding: 0 1rem;
  transition: transform 0.3s ease;

  @include tablet {
    font-size: 14px;
  }

  &:hover {
    transform: scale(1.1); // Slight zoom on hover
  }

  &__text {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }
}
