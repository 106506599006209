@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.contact-me-page {

  text-wrap: balance;
    
    @include tablet {
    // Additional tablet-specific styles
  }

  @include desktop {
    // Additional desktop-specific styles
  }
}

