@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.header {
  padding: $mobile-padding;
  color: $black;

  @include tablet {
    padding: $tablet-padding;
  }

  @include desktop {
    padding: $desktop-padding;
  }

  position: sticky;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $brand2;

  &__logo {
    height: 1.5rem;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.1); // Slight zoom on hover
    }
  }

  &__links {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.0125rem;
    gap: 1rem;

    & a {
      text-decoration: none;
      color: inherit;
      cursor: pointer !important;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1); // Slight zoom on hover
        color: $brand3;
      }
    }

    @include tablet {
      gap: 1.5rem;
    }

    @include desktop {
      gap: 2rem;
    }

    @media (max-width: 768px) {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      background-color: $brand2;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1rem;
      width: 100%;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

      &--open {
        display: flex;
        font-family: $font;
        font-size: 28px;
        gap: 2rem;
        font-variation-settings: 'wdth' 75, 'wght' 200, 'ital' 0;
      }
    }
  }

  &__hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    flex-direction: column;
    gap: 0.3rem;

    span {
      display: block;
      width: 25px;
      height: 3px;
      background: $black;
      transition: transform 0.3s, background 0.3s;
    }

    &--open span:nth-child(1) {
      transform: rotate(45deg) translateY(8px);
    }

    &--open span:nth-child(2) {
      opacity: 0;
    }

    &--open span:nth-child(3) {
      transform: rotate(-45deg) translateY(-8px);
    }

    @media (max-width: 768px) {
      display: flex;
    }
  }
}
