@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.portfolio-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px; /* Adjust gap as needed */
  margin: 16px 0;
}

.portfolio-grid__item {
  position: relative;
  border: .5px solid #a0a0a0;
}

.portfolio-grid__item__image-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  padding-top: 75%; /* 4:3 aspect ratio */
}

.portfolio-grid__item__image-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.portfolio-grid__item__image-container:hover img {
  transform: scale(1.1); /* Zoom effect */
}

.portfolio-grid__item__text-container {
  text-align: left;
  margin: 10px; /* Space between image and text */
}

/* Remove margins for h4 and h5 */
.portfolio-grid__item__text-container h4,
.portfolio-grid__item__text-container h5 {
  margin: 0; /* Remove top and bottom margins */
}

@media (max-width: 768px) {
  .portfolio-grid-container {
    grid-template-columns: 1fr; /* 1 column on mobile */
  }
}
