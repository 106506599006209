@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;


.service-card {
    background-color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 4rem;
    text-wrap: balance;
    height: auto; /* Let the height scale proportionally */
    border-radius: 2px;
    border: .5px solid $black;
 

  &__image {
    width: 100%; /* Make the image container take up the full width of the card */
    height: auto; /* Let the height scale proportionally */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Hide any overflow to prevent distortion */
  
    img {
      width: 100%; /* Scale the image to fit the width of the container */
      height: 100%; /* Ensures the image fills the container */
      object-fit: cover; /* Maintain aspect ratio and fill the container */
    }
  }
  
  &__content {
    text-align: center;
    padding-top: 2rem;
  }

  &__heading {
    padding-bottom: 1rem;
  }

  &__description {
    padding-bottom: 2rem;
  }
}