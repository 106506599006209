@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.contact-me {
    background-color: $softblack;
    padding: 4rem 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-wrap: balance;

    h2, h5 {
        color: $brand1 !important;
        padding-bottom: 2rem;
    }

    @include tablet {
        padding: 6rem 12rem; 
    }

    @include desktop {
        padding: 6rem 22rem; 
    }

    &__button-container {
        display: flex;
        align-items: center;        
        justify-content: center;  
        gap: 0.6rem;
        margin: 0.8rem 0;
        flex-wrap: wrap;      
    }
}