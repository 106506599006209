@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.recent-work-carousel-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 10rem 0;
    background-color: $brand2;

    h2 {
      padding-bottom: 2rem;
    }
}



.recent-work-carousel {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 20px 0;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch; /* For smooth scrolling on mobile */
    
    /* Preventing items from wrapping to next line */
    flex-wrap: nowrap;
    
    /* Hide horizontal scrollbar */
    &::-webkit-scrollbar {
      display: none;
    }
  }
  
  .portfolio-item {
    flex: 0 0 auto;
    width: 280px; /* Adjust as needed */
    scroll-snap-align: center;
    position: relative;
    border-radius: 2px;
    border: .5px solid $black;
    overflow: hidden;
    background-color: $white;
    
    .image-container {
      width: 100%;
      height: 200px; /* Set image height as needed */
      overflow: hidden;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
  
    .text-container {
      padding: 10px;
      text-align: center;
      
      h5 {
        margin: 0;
        font-weight: bold;
        color: $softblack;
        text-wrap: balance;
      }
  
      h4 {
        margin: 5px 0 0;
      }
    }
  }
  
  /* Media Queries for responsiveness */
  
  /* Mobile devices */
  @media (max-width: 600px) {
    .recent-work-carousel {
      padding: 10px 0;
    }
    
    .portfolio-item {
      width: 240px; /* Adjust for smaller screens */
    }
  }
  
  /* Tablet devices */
  @media (max-width: 1024px) {
    .portfolio-item {
      width: 300px; /* Slightly larger for tablets */
    }
  }
  
  /* Desktop and larger screens */
  @media (min-width: 1025px) {
    .recent-work-carousel {
      gap: 30px; /* Larger gap on desktops */
    }
  }
  