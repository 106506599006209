@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.home-page {

  @include tablet {
    // Additional tablet-specific styles
  }

  @include desktop {
    // Additional desktop-specific styles
  }


  &__heading-container {
        text-wrap: balance;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;

    @include tablet {
        max-width: 75%;
      }
    
      @include desktop {
        max-width: 50%;
      }
    }

    &__button-container {
        display: flex;
        align-items: center;        
        justify-content: center;  
        gap: 0.8rem;
        margin: 0.8rem 0;
        flex-wrap: wrap;
        z-index: 0;
        
        @include tablet {
          gap: 0.6rem;
        }
    }

    &__service-cards-container {
      display: flex;
      flex-wrap: wrap; /* Allow cards to wrap */
      gap: 1rem; /* Space between the cards */
      justify-content: center; /* Center the cards */
      align-items: stretch; /* Ensure cards stretch to the same height */
      box-sizing: border-box;
      padding-bottom: 4rem;
      
    @include tablet {
        max-width: 85%;
        padding-bottom: 8rem;
      }
    
      @include desktop {
        max-width: 85%;
      }
    }

    &__service-cards {
      flex: 1 1 calc(100% - 1rem); /* Full width for small screens */
      // max-width: calc(100% - 1rem);
      display: flex; /* Ensure content inside stretches */
      justify-content: space-between; /* Distribute content evenly */
      box-sizing: border-box;
     
      @include tablet {
        flex: 1 1 calc(50% - 1rem); /* Two cards per row on tablet */
        max-width: calc(50% - 1rem);
      }
    
    }
}

    // Increase specificity by targeting the elements within .home-page
    .home-page h1 {
      margin: 0;
      z-index: 0;
    }
  
    .home-page h3 {
     margin: 2rem;
     z-index: 0;
    }