@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;


.service-section {
  background-color: $brand2;
  padding-bottom: 3rem;
  padding-top: 3rem;
  text-wrap: balance;

  h1 {
    padding-bottom: 4rem;
  }

  h6 {
    letter-spacing: 0.25rem !important;
    padding: 2rem 0;
  }
  
  &__content {
    display: flex;
    flex-direction: column;
    padding: 1rem;

    @include tablet {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__text {
    padding-right: 1rem;

    @include tablet {
      flex: 1;
      padding: 4rem 0 4rem 4rem;
    }
  }

  &__button {
    padding-top: 3rem;
  }

  &__image {
    text-align: center;
    padding: 3rem 1rem 0 0;

    @include tablet {
      flex: 1;
      max-width: 100%;
    }

    img {
      width: 100%;
      height: auto;
      object-fit: cover;

      @include tablet {
        width: 85%;
      }
    }
  }
}
