@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.footer {
  display: flex;
  flex-direction: column;
  padding: 10rem 1rem;
  text-wrap: balance;
  background-color: $white;


  @include tablet {
    flex-direction: row;
    gap: 10rem;
    padding: 10rem 3rem;
  }

  @include desktop {
  }

  // Logo styles
  &__logo {
    width: 10rem;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }

  &__button-container {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.6rem;
    margin: 2rem 0 3rem 0;
    flex-wrap: wrap;
  }

  &__column-1,
  &__column-2,
  &__column-3 {
    flex: 1;               // Make columns take equal space
    margin-bottom: 6rem;   // Ensure consistent spacing
  }

  &__phone {
    color: $black;
    text-decoration: none;
  }

  &__social-media {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.5rem;
    margin-top: 2rem;

    .footer__social-icon {
      width: 2.5rem;
      height: auto;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1); // Slight zoom on hover
      }
    }

  }

  h2 {
    margin-bottom: 1rem;
  }
}
