@use "../../styles/partials/variables" as *;
@use "../../styles/partials/mixins" as *;

.learn-more-button {
  border: none;
  border-radius: 2px;
  padding: 10px 16px;
  font-family: $font;
  font-size: 16px;
  color: $black;
  background-color: $white;
  letter-spacing: 0.0125rem;
  text-decoration: none;
  text-align: center;
  border: 1px solid $black;
  display: inline-flex; // Ensure proper alignment for label and arrow
  align-items: center;
  gap: 8px; // Space between the label and the arrow
  transition: transform 0.3s ease;

  .arrow {
    display: inline-block;
    margin-left: 5px;
    border: solid $black;
    border-width: 0 1px 1px 0;
    padding: 3px;
    transform: rotate(-45deg);
    transition: transform 0.2s ease;
  }

  &:hover {
    background-color: $brand3;
    letter-spacing: 0.0125rem;
    transform: scale(1.1); // Slight zoom on hover

    .arrow {
      transform: rotate(320deg); // Reverse the rotation on hover
      border-width: 0 2px 2px 0;
    }

  @include tablet {
    font-size: 14px;
  }
}
}