@use "variables" as *;



@mixin H($font-family, $font-size, $letter-spacing, $line-height, $font-weight, $color) {
  font-family: $font-family;
  font-size: $font-size;
  letter-spacing: $letter-spacing;
  line-height: $line-height;
  font-weight: $font-weight;
  color: $color;
}

@mixin tablet {
  @media screen and (min-width: $tablet-breakpoint) {
    @content;

    h1 {
      @include H($heading-font, 150px, 0, 140px, 800, $black);
    }
    
    h2 {
      @include H($font, 32px, 0, 40px, 500, $black);
    }
    
    h3 {
      @include H($font, 20px, 0, 24px, 200, $black);
    }
    
    h4 {
      @include H($font,24px, 0, 26px, 300, $black);
    }
    
    h5 {
      @include H($font, 18px, .0125rem, 24px, 400, $black);
    }
    
    h6 {
      @include H($font, 16px, 0, 22px, 300, $black);
    }
  }
}

@mixin desktop {
  @media screen and (min-width: $desktop-breakpoint) {
    @content;
    h1 {
      @include H($heading-font, 180px, 0, 180px, 800, $black);
    }
    
    h2 {
      @include H($font, 38px, 0, 44px, 500, $black);
    }
    
    h3 {
      @include H($font, 24px, 0, 26px, 200, $black);
    }
    
    h4 {
      @include H($font,22px, 0, 24px, 300, $black);
    }
    
    h5 {
      @include H($font, 18px, .0125rem, 24px, 400, $black);
    }
    
    h6 {
      @include H($font, 16px, 0, 22px, 300, $black);
    }
  }
}

 /*mobile type*/

 h1 {
  @include H($heading-font, 90px, -0.0125rem, 92px, 800, $black);
}

h2 {
  @include H($font, 28px, 0rem, 32px, 500, $black);
}

h3 {
  @include H($font, 20px, 0, 22px, 200, $black);         
}

h4 {
  @include H($font, 24px, 0, 26px, 300, $black);
}

h5 {
  @include H($font, 17px, .0125rem, 21px, 400, $black);
}

h6 {
  @include H($font, 14px, 0rem, 18px, 300, $black);
}


@mixin respond-to($breakpoint) {
  @if $breakpoint == "tablet" {
    @media (min-width: $tablet-breakpoint) {
      @content;
    }
  } @else if $breakpoint == "desktop" {
    @media (min-width: $desktop-breakpoint) {
      @content;
    }
  } @else if $breakpoint == "mobile" {
    @media (max-width: $mobile-breakpoint) {
      @content;
    }
  }
}
